<template>
	<div class="materialBanner" >
		<div class="card-carousel-wrapper" >
			<div class="card-carousel--nav__left card-carousel--nav" @click="moveCarousel(-1)" :disabled="atHeadOfList">
			</div>
			<div class="card-carousel">
			  <div class="card-carousel--overflow-container k_flex_jcc_aic" :class="selectIndex == item.id?'active':''" v-for="item in menuList" :key="item.id" @click="selectBanner(item)" >
			    {{item.name}}
			  </div>
			</div>
			<div class="card-carousel--nav__right card-carousel--nav" @click="moveCarousel(1)" :disabled="atEndOfList">
			</div>
		</div>
		<img class="card-carousel-img" :src="bannerUrl+selectImg"/>
	</div>
</template>

<script>
import i18n from "@/lang"
export default{
	components:{
		
	},
	data() {
		return {
			// menuList:[
			// 	{id:1,value:'paperBicolor',name:'双色热敏纸',img:'banner_02.jpg'},
			// 	{id:2,value:'paperFourproofing',name:'四防热敏纸',img:'banner_01.jpg'},
			// 	{id:3,value:'paperCompound',name:'热敏合成纸',img:'banner_03.jpg'},
			// 	{id:4,value:'paperCopper',name:'热敏铜版纸',img:'banner_06.jpg'},
			// 	{id:5,value:'washHigh',name:'热敏高温水洗唛',img:'banner_05.jpg'},
			// 	{id:6,value:'washNormal',name:'热敏常温水洗唛',img:'banner_04.jpg'},
			// ],
			selectImg:'',
			selectIndex:1,
			
		}
	},
	props:{
		menuIndex:{
			type: Number,
			default: 1
		}
	},
	computed: {
	  atEndOfList() {
	    return this.selectIndex>=this.menuList.length
	  },
	  atHeadOfList() {
	    return this.selectIndex <= 1
	  },
		bannerUrl() {
		  return 'https://oss-www.kymasf.com/img/material/banner/'
		},
		menuList(){
		return [
				// {id:1,value:'paperBicolor',name:this.$t('m.DualColorPaper'),img:'banner_02.jpg'},
				// {id:2,value:'paperCopper',name:this.$t('m.UltrarPaper'),img:'banner_01.jpg'},
				// {id:3,value:'paperFourproofing',name:this.$t('m.SyntheticPaper'),img:'banner_03.jpg'},
				// {id:4,value:'washHigh',name:this.$t('m.ThermalPaper'),img:'banner_06.jpg'},
				// {id:5,value:'paperCompound',name:this.$t('m.HighWashLabel'),img:'banner_05.jpg'},
				// {id:6,value:'washNormal',name:this.$t('m.NormalWashLabel'),img:'banner_04.jpg'},

				{id:1,value:'paperBicolor',name:this.$t('m.DualColorPaper'),img:this.$t('m.materialbanner1')},
				{id:2,value:'paperFourproofing',name:this.$t('m.UltrarPaper'),img:this.$t('m.materialbanner2')},
				{id:3,value:'paperCompound',name:this.$t('m.SyntheticPaper'),img:this.$t('m.materialbanner3')},
				{id:4,value:'paperCopper',name:this.$t('m.ThermalPaper'),img:this.$t('m.materialbanner4')},
				// {id:5,value:'washHigh',name:this.$t('m.HighWashLabel'),img:this.$t('m.materialbanner5')},
				// {id:6,value:'washNormal',name:this.$t('m.NormalWashLabel'),img:this.$t('m.materialbanner6')},
				
			]
		},
		i18nlocale(){
			return i18n.locale
		},
	},

	mounted() {
		this.selectIndex = this.menuIndex
		this.getSelect()
	},
	watch:{
		menuList:{
			handler(newVal,oldVal){
					this.getSelect()
			},             
			deep:true
			}


	},
	methods: {
		getSelect(){
			let obj = this.menuList.filter((item)=>item.id == this.selectIndex)[0]
			this.selectBanner(obj)
		},
		selectBanner(item){
			this.selectIndex = item.id
			this.$nextTick(()=>{
					 this.selectImg = item.img
			})

			this.$router.push('/material/'+item.value+'/'+this.i18nlocale)
		
				// this.$router.push('/material/'+item.value)
			
			
			
		},
		moveCarousel(direction){
			let sum = this.selectIndex+direction
			if(sum>this.menuList.length||sum<1){
				return false
			}
			this.selectIndex = sum
			// if(this.selectIndex>6){
			// 	this.selectIndex = this.selectIndex - 6
			// }else if(this.selectIndex<1){
			// 	this.selectIndex = this.selectIndex + 6
			// }
			this.getSelect()
		}
	}
}
</script>

<style lang="less" scoped>
.materialBanner{
	width: 100%;
	// height: 86px;
	// padding: 0;
	// margin: 0;
	background: #fff;
	.card-carousel-wrapper {
		width: 1200px;
		margin: 0 auto;
		padding: 0 30px;
		height: 86px;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  color: #666a73;
	  .card-carousel--nav__left{
			background: url(https://oss-www.kymasf.com/img/material/banner/left.png) no-repeat;
			background-size: 36px 36px;
		}
		.card-carousel--nav__left:hover{
			background: url(https://oss-www.kymasf.com/img/material/banner/left_active.png) no-repeat;
			background-size: 36px 36px;
		}
	  .card-carousel--nav__right{
			background: url(https://oss-www.kymasf.com/img/material/banner/right.png) no-repeat;
			background-size: 36px 36px;
		}
		.card-carousel--nav__right:hover{
			background: url(https://oss-www.kymasf.com/img/material/banner/right_active.png) no-repeat;
			background-size: 36px 36px;
		}
		.card-carousel--nav{
	    display: inline-block;
	    width: 36px;
	    height: 36px;
	    cursor: pointer;
	    margin: 0 auto;
	    transition: transform 150ms linear;
			background-size: 36px 36px;
	    // margin-bottom: 24px;
	  }
	  .card-carousel--nav__left[disabled]{
			background: url(https://oss-www.kymasf.com/img/material/banner/left_disable.png) no-repeat;
			background-size: 36px 36px;
		}
	  .card-carousel--nav__right[disabled] {
	    background: url(https://oss-www.kymasf.com/img/material/banner/right_disable.png) no-repeat;
	    background-size: 36px 36px;
	  }
	  .card-carousel {
			width: 810px;
	    display: flex;
	    justify-content: space-between;
			align-items: center;
	    min-width: 374px;
	    overflow: hidden;
	    margin: 0 20px;
			cursor: pointer;
	    .card-carousel--overflow-container {
	      overflow: hidden;
				width: 120px;
				height: 86px;
				box-sizing: border-box;
	    }
			.active{
				color: #2D6CFF;
				border-bottom: 2px solid #2D6CFF;
			}
	  }
	}
	.card-carousel-img{
		display: inline-block;
		width: 100%;
		min-width: 1200px;
		background: #000;
	}
}
</style>