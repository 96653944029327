<template>
	<div class="paperCopper">
		<!-- 材料导航 -->
		<material-banner :menuIndex="4"></material-banner>
		
		<!-- 产品特点 -->
		<div class="region-com region-trait k_flex_column_jcc_aic">
			<div class="region-title k_flex_jcc_aic">
				<div class="region-title-left k_flex_jcc_aic">
					<span class="k_flex_jcc_aic">01</span>
				</div>
				<div class="region-title-right k_flex_column_aifs">
					<span>{{ $t('m.ProductFeatures') }}</span>
					<span>{{ $t('m.Multipleadvantages') }}</span>
				</div>
			</div>
			<div class="region-desc k_flex_wrap_jcsb">
				<div v-for="(item,index) in traitList" class="region-desc-single" :class="index>0?'normal':'special'" :key="index">
					<img v-if="index<1" class="region-desc-single-img" :src="paperCopperUrl+item.img"/>
					<div v-if="index<1" class="region-desc-single-cont k_flex_column_aifs">
						<span>{{item.title}}</span>
						<span></span>
						<span>{{item.content}}</span>
						<div class="k_flex_aic">
							<img :src="paperCopperUrl+item.special_img"/>
							<div>{{item.special_cont}}</div>
						</div>
					</div>
					<img v-if="index>=1" class="region-desc-single-img" :class="index%2>0?'odd':'even'" :src="paperCopperUrl+item.img"/>
					<div v-if="index>=1" class="region-desc-single-cont k_flex_column_aifs"  :class="index%2>0?'odd-cont':'even-cont'">
						<span>{{item.title}}</span>
						<span>{{item.content}}</span>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 细节展示 -->
		<div class="region-com region-detail k_flex_column_jcc_aic">
			<div class="region-title k_flex_jcc_aic">
				<div class="region-title-left k_flex_jcc_aic">
					<span class="k_flex_jcc_aic">02</span>
				</div>
				<div class="region-title-right k_flex_column_aifs">
					<span>{{ $t('m.Detaileddisplay') }}</span>
					<span>{{ $t('m.Qualityassurance') }}</span>
				</div>
			</div>
			<div class="region-desc k_flex_column_aic">
				<div v-for="(item,index) in detailList" class="region-desc-single" :class="index%2>0?'k_flex_rowreverse_aic':'k_flex_aic'" :key="index">
					<img :src="paperCopperUrl+item.img"/>
					<div class="region-desc-single-content k_flex_column_aifs">
						<span>{{item.title}}</span>
						<span></span>
						<span v-for="(itemT,indexT) in item.content" :key="indexT">{{itemT}}</span>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 产品展示 -->
		<div class="region-com region-display k_flex_column_jcc_aic">
			<div class="region-title k_flex_jcc_aic">
				<div class="region-title-left k_flex_jcc_aic">
					<span class="k_flex_jcc_aic">03</span>
				</div>
				<div class="region-title-right k_flex_column_aifs">
					<span>{{ $t('m.Products1') }}</span>
					<span>{{ $t('m.Qualityensuredandsuitable') }}</span>
				</div>
			</div>
			<div class="region-desc k_flex_jcsb_aic">
				<img :src="paperCopperUrl+'display_01.jpg'"/>
				<div class="region-desc-bottom k_flex_column_jcsb_aic">
					<img :src="paperCopperUrl+'display_02.jpg'"/>
					<img :src="paperCopperUrl+'display_03.jpg'"/>
				</div>
			</div>
		</div>
		
		<!-- 适用行业 -->
		<div class="region-com region-suitable k_flex_column_jcc_aic">
			<div class="region-title k_flex_jcc_aic">
				<div class="region-title-left k_flex_jcc_aic">
					<span class="k_flex_jcc_aic">04</span>
				</div>
				<div class="region-title-right k_flex_column_aifs">
					<span>{{ $t('m.Applications') }}</span>
					<span>{{ $t('m.Suitableforvariousindustries') }}</span>
				</div>
			</div>
			<div class="region-desc k_flex_jcsb_aic">
				<img v-for="(item,index) in suitableList" :key="index" :src="paperCopperUrl+item.img"/>
			</div>
		</div>
		
		<!-- 产品规格 -->
		<div class="region-com region-size k_flex_column_jcc_aic">
			<div class="region-title k_flex_jcc_aic">
				<div class="region-title-left k_flex_jcc_aic">
					<span class="k_flex_jcc_aic">05</span>
				</div>
				<div class="region-title-right k_flex_column_aifs">
					<span>{{ $t('m.TechnicalSpecification') }}</span>
					<span>{{ $t('m.Qualityassurance') }}</span>
				</div>
			</div>
			<div class="region-cont">
				<el-table
					:data="tableData"
					:span-method="objectSpanMethod"
					border
					style="width: 100%;font-size: 16px;">
					<el-table-column
						prop="name"
						label="产品名称"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Product')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="type"
						label="产品型号"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Model')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="standards"
						label="产品规格"
						align="center"
						width="160">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Specification')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="metre"
						label="米数"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Length')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="introduce"
						label="介绍"
						align="center"
						width="599">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Introduction')}}</div>
						</template>
						<template slot-scope="scope">
							<div class="region-cont-introduce">
								<div class="region-cont-introduce-single" v-for="(item,index) in scope.row.introduce">
									<span>{{item.title}}</span>
									<span>{{item.content}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<img :src="paperCopperUrl+$t('m.paperCoppersuisize')" class="region-desc"/>
		</div>
	</div>
</template>

<script>
import materialBanner from '@c/materialBanner/materialBanner'
export default{
	components:{
		materialBanner,
	},
	data() {
		return {
			// suitableList:[
			// 	{
			// 		img:'suitable_01.jpg'
			// 	},
			// 	{
			// 		img:'suitable_02.jpg'
			// 	},
			// 	{
			// 		img:'suitable_03.jpg'
			// 	},
			// 	{
			// 		img:'suitable_04.jpg'
			// 	}
			// ],
			// tableData: [
			// 	{
			// 		name:this.$t('m.ThermalPaper') ,
			// 		type: 'th600',
			// 		standards: '1090mm',
			// 		metre: '8000m',
			// 		introduce: [
			// 			{
			// 				title:'无需碳带打印：',
			// 				content:'不需要专岗专职人员操作，不再频繁更换碳带，操作更加高效便捷；同时也更加环保，避免产生固体废弃物；'
			// 			},
			// 			{
			// 				title:'长久保存：',
			// 				content:'使用了涂层特殊，打印效果稳定性得以改进，发色浓郁，通过国家纸张检测中心标准验证长效保存五年以上；'
			// 			},
			// 			{
			// 				title:'纸张表面平滑度更高：',
			// 				content:'有效保护打印头，手感细腻光滑，贴近皮肤肌理；'
			// 			},
			// 			{
			// 				title:'耐候性好：',
			// 				content:'防水防油防刮擦耐塑，适用于多种场景；'
			// 			}
			// 		]
			// 	}, 
			// 	{
			// 		name: '',
			// 		type: '',
			// 		standards: '1090mm',
			// 		metre: '6000m',
			// 		introduce: []
			// 	},
			// 	{
			// 		name: '',
			// 		type: '',
			// 		standards: '1550mm',
			// 		metre: '12000m',
			// 		introduce: []
			// 	},
			// 	{
			// 		name: '',
			// 		type: '',
			// 		standards: '1650mm',
			// 		metre: '12000m',
			// 		introduce: []
			// 	}
			// ],
			// detailList:[
			// 	{
			// 		img:'detail_01.jpg',
			// 		title:'打印清晰',
			// 		content:[
			// 			'面材平整光洁，不毛糙，打印效果清晰可见，sgs国际检测机构专业检测，长途运输摩擦，胶带 粘贴不掉色不破损'
			// 		]
			// 	},
			// 	{
			// 		img:'detail_02.jpg',
			// 		title:'工厂直销、品质保证',
			// 		content:[
			// 			'采用优质品牌原材料，生产车间严格把控，制造高品质的优质产品',
			// 		]
			// 	}
			// ],
			// traitList:[
			// 	{
			// 		img:'trait_01.jpg',
			// 		title:'无需碳带',
			// 		content:'无需碳带打印，不需要专岗专职人员操作，不再频繁更换碳带，操作更加高效便捷；同时也更加环保，避免产生固体废弃物',
			// 		special_img:'trait_logo.png',
			// 		special_cont:'告别碳带、降低成本'
			// 	},
			// 	{
			// 		img:'trait_02.jpg',
			// 		title:'防水',
			// 		content:'水中浸泡30分钟以上字迹依然清晰'
			// 	},
			// 	{
			// 		img:'trait_03.jpg',
			// 		title:'防油',
			// 		content:'油中浸泡28小时，色密度仍在1.0以上'
			// 	},
			// 	{
			// 		img:'trait_04.jpg',
			// 		title:'防刮擦',
			// 		content:'硬物来回刮擦，字迹依旧清晰'
			// 	},
			// 	{
			// 		img:'trait_05.jpg',
			// 		title:'耐塑',
			// 		content:'sgs国际检测机构专业 检测，长途运输摩擦， 胶带粘贴不掉色不破损'
			// 	}
			// ]
		}
	},
	computed: {
		paperCopperUrl() {
		  return 'https://oss-www.kymasf.com/img/material/paperCopper/'
		},
		traitList(){
			return [
				{
					img:'trait_01.jpg',
					title:this.$t('m.NoNeedforRibbon'),
					content:this.$t('m.Ribbonlessprinting'),
					special_img:'trait_logo.png',
					special_cont:this.$t('m.Reducecost')
				},
				{
					img:'trait_02.jpg',
					title:this.$t('m.Waterproof'),
					content:this.$t('m.Textremainclearafter')
				},
				{
					img:'trait_03.jpg',
					title:this.$t('m.OilResistant2'),
					content:this.$t('m.Afterthecolordensityis')
				},
				{
					img:'trait_04.jpg',
					title:this.$t('m.ScratchResistant2'),
					content:this.$t('m.Textremainclearafterscratching')
				},
				{
					img:'trait_05.jpg',
					title:this.$t('m.PlasticizerResistant2'),
					content:this.$t('m.Nopeelandloseofcolorafter')
				}
			]
		},
		detailList(){
			return[
				{
					img:'detail_01.jpg',
					title:this.$t('m.Clearprinting'),
					content:[
					this.$t('m.Thesurfacesmoothandcleanandtheprinting')
					]
				},
				{
					img:'detail_02.jpg',
					title:this.$t('m.Directsalesfrommanufacturer'),
					content:[
					this.$t('m.Weadoptehighqualityrawmaterials')
					]
				}
			]
		},
		tableData(){
			return [
				{
					name:this.$t('m.ThermalPaper') ,
					type: 'th600',
					standards: '1090mm',
					metre: '8000m',
					introduce: [
						{
							title:this.$t('m.Noneedofribbontitle'),
							content:this.$t('m.Noneedofribbon')
						},
						{
							title:this.$t('m.copperLongshelflifetitle'),
							content:this.$t('m.copperLongshelflife')
						},
						{
							title:this.$t('m.Highersmoothnessofsurfacetitle'),
							content:this.$t('m.Highersmoothnessofsurface')
						},
						{
							title:this.$t('m.Goodresistancetoharshenvironmenttitle'),
							content:this.$t('m.Goodresistancetoharshenvironment')
						}
					]
				}, 
				{
					name: '',
					type: '',
					standards: '1090mm',
					metre: '6000m',
					introduce: []
				},
				{
					name: '',
					type: '',
					standards: '1550mm',
					metre: '12000m',
					introduce: []
				},
				{
					name: '',
					type: '',
					standards: '1650mm',
					metre: '12000m',
					introduce: []
				}
			]
		},
		suitableList(){
			return[
				{
					img:this.$t('m.paperCoppersuitableListimg1')
				},
				{
					img:this.$t('m.paperCoppersuitableListimg2')
				},
				{
					img:this.$t('m.paperCoppersuitableListimg3')
				},
				{
					img:this.$t('m.paperCoppersuitableListimg4')
				}
			]
		}
	},
	mounted() {
		
	},
	methods: {
		objectSpanMethod({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0 || columnIndex === 1 || columnIndex === 4) {
				if (rowIndex % 4 === 0) {
					return {
						rowspan: 4,
						colspan: 1
					};
				} else {
					return {
						rowspan: 0,
						colspan: 0
					};
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.paperCopper{
	width: 100%;
	height: 100%;
	background: #fff;
	// 区域公共样式
	.region-com{
		width: 100%;
		.region-title{
			.region-title-left{
				width: 84px;
				height: 84px;
				background: #48658F;
				border-radius: 3px;
				span{
					width: 71px;
					height: 71px;
					background: #48658F;
					border: 2px dashed rgba(255,255,255,0.7);
					border-radius: 3px;
					font-size: 38px;
					font-family: Adobe Heiti Std;
					font-weight: normal;
					color: #FFFFFF;
				}
			}
			.region-title-right{
				margin-left: 14px;
				span{
					&:nth-child(1){
						font-size: 36px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #48658F;
					}
					&:nth-child(2){
						font-size: 26px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #1C1C1C;
					}
				}
			}
		}
	}
	
	//产品特点
	.region-trait{
		.region-title{
			margin-top: 75px;
		}
		.region-desc{
			margin-top: 50px;
			margin-bottom: 68px;
			width: 1200px;
			.region-desc-single{
				
			}
			.normal{
				width: 554px;
				height: 260px;
				background: #343B45;
				position: relative;
				margin-top: 64px;
				&:nth-child(n+4){
					margin-top: 51px;
				}
				.region-desc-single-cont{
					position: absolute;
					width: 180px;
					span{
						&:nth-child(1){
							font-size: 26px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #FFFFFF;
							margin-top: 25px;
						}
						&:nth-child(2){
							margin-top: 10px;
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
						}
					}
				}
				.odd-cont{
					left: 24px;
				}
				.even-cont{
					right: 24px;
				}
				.odd{
					position: absolute;
					right: -24px;
					top: -24px;
				}
				.even{
					position: absolute;
					left: -24px;
					top: -24px;
				}
			}
			.special{
				width: 1200px;
				padding: 15px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				background: #3C5880;
				.region-desc-single-cont{
					width: 533px;
					margin-left: 20px;
					height: 382px;
					background: #fff;
					padding: 25px 10px 15px 30px;
					box-sizing: border-box;
					span{
						&:nth-child(1){
							font-size: 42px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #3C5880;
						}
						&:nth-child(2){
							width: 73px;
							height: 10px;
							// background: #3C5880;
							// margin-top: 5px;
							// margin-bottom: 5px;
						}
						&:nth-child(3){
							font-size: 22px;
							font-family: Adobe Heiti Std;
							font-weight: normal;
							color: #1C1C1C;
							margin-bottom: 40px;
						}
					}
					div{
						margin-left: 20px;
						div{
							margin-left: 40px;
							font-size: 29px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #3C5880;
						}
					}
				}
			}
		}
	}
	
	//细节展示
	.region-detail{
		background: #EFF1F2;
		.region-title{
			margin-top: 40px;
		}
		.region-desc{
			margin-top: 77px;
			margin-bottom: 54px;
			.region-desc-single{
				&:nth-child(n+2){
					margin-top: 60px;
				}
				.region-desc-single-content{
					width: 600px;
					padding: 50px;
					height: 390px;
					box-sizing: border-box;
					span{
						&:nth-child(1){
							font-size: 28px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #48658F;
						}
						&:nth-child(2){
							width: 44px;
							height: 5px;
							background: #48658F;
							margin-top: 12px;
							margin-bottom: 37px;
						}
						&:nth-child(n+3){
							font-size: 20px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #1C1C1C;
						}
					}
				}
			}
		}
	}
	
	//产品展示
	.region-display{
		.region-title{
			margin-top: 46px;
		}
		.region-desc{
			width: 1200px;
			margin-top: 60px;
			margin-bottom: 68px;
			.region-desc-bottom{
				height: 711px;
				width: 591px;
			}
		}
	}
	
	//适用行业
	.region-suitable{
		background: #EFF1F2;
		.region-title{
			margin-top: 53px;
		}
		.region-cont{
			
		}
		.region-desc{
			width: 1200px;
			margin-top: 58px;
			margin-bottom: 61px;
		}
	}
	
	//产品规格
	.region-size{
		// background: #FFFFFF;
		.region-title{
			margin-top: 50px;
		}
		.region-cont{
			margin-top: 30px;
			width: 1200px;
			background: #AFBEE9;
			padding: 10px;
			border-radius: 8px;
			box-sizing: border-box;
			font-size: 16px;
			.region-cont-introduce{
				.region-cont-introduce-single{
					font-size: 16px;
					text-align: left;
				}
			}
		}
		.region-desc{
			width: 1200px;
			margin-top: 30px;
			margin-bottom: 55px;
		}
	}
}
</style>